export const language = {
	exhibitAreaInfo: "展区信息",
	highlights: "精彩瞬间",
	mediaCoverage: "媒体报道",
	announcements: "通知公告",
	conventionUpdates: "大会资讯",
	newsCenter: "资讯中心",
	more: "更多",
	// 以上2024新
	index: "首页",
	conventionActivities: "大会活动",
	exhibitions: "大会展示",
	moreList: "更多列表",
	conventionServices: "大会服务",
	about: "关于大会",
	majorEvents: "重大活动",
	projectDocking: "重大项目对接活动",
	thematicActivities: "重点专项活动",
	// forumActivities: "论坛活动",
	// professionalForum: "专业化论坛",
	// marketizationForum111: "各市举办的活动",
	bulletinBoard: "大会看板",
	epidemicPreventionTips: "防疫提示",
	ticketHandling: "票证办理",
	brandCooperation: "品牌合作",
	investmentServices: "投资服务",
	trafficGuide: "交通指引",
	exhibitionAndConventionCenter: "会展中心",
	Alipay: "支付指南",
	conferenceSignage: "大会看板",
	eventSchedule: "活动日程",
	activityTime: "活动时间",
	activityAddress: "活动地点",
	responsibleUnit: "责任单位",
	dataTime: "时间：",
	address: "地点：",
	content: "内容：",
	responseOrganization: "责任单位：",
	// 敬请期待
	jqqd: "敬请期待",

	// 主办单位
	sponsor: "主办单位",
	// 安徽省人民政府
	ahsrmzf: "安徽省人民政府",
	// 国家制造强国建设战略咨询委员会
	gjzzqgjszlwyh: "国家制造强国建设战略咨询委员会",
	// 中国中小企业协会
	zgzxqyxh: "中国中小企业协会",
	// 全球中小企业联盟
	qqzxqylm: "全球中小企业联盟",
	// 支持单位:
	supportingAgency: "支持单位",
	// 中国侨联
	zgql: "中国侨联",
	// 中国机械工业集团有限公司
	zgjxgyjt: "中国机械工业集团有限公司",
	// 中国企业联合会
	zgqylhh: "中国企业联合会",
	// 中国机械工业联合会
	zgjxgylhh: "中国机械工业联合会",
	// 中国光伏行业协会
	zggfhyxh: "中国光伏行业协会",
	// 中国电子信息产业发展研究院
	zgdzxxcyfayjy: "中国电子信息产业发展研究院",

	zggyhlwyjy: "中国工业互联网研究院",


	// 承办单位
	organizer: "承办单位",
	// 安徽省经济和信息化厅
	sgyhxxht: "省工业和信息化厅",

	ansswt: "省商务厅",
	// 省外办（省港澳办）
	swbswt: "省外办（省港澳办）",
	// 省国资委
	sgzw: "省国资委",
	// 省贸促会
	smch: "省贸促会",
	// 合肥市人民政府
	hfsrmzf: "合肥市人民政府",

	// 距2023世界制造业大会开幕
	djs1: "距离大会开幕还有",
	// 倒计时    天：
	djs2: "天",
	// 线上展会：
	onlineFair: "线上展厅",
	// 进入展厅：
	entertheExhibitionHall: "世界制造业大会实景展",
	// 进入2021届世界制造业大会云看展：
	virtualExhibitionof2021WorldManufacturingConvention: "2023世界制造业大会虚拟展",
	// 精彩瞬间：
	brilliantMoments: "精彩瞬间",
	// 六百对接：（中文专有名词，无对应英文译文，建议翻译成“对接会”，按原文直译的话外国人也不明白是什么意思）
	matchmakingConference: "六百对接",
	// 大会简介：
	conventionIntroduction: "大会简介",
	// 组织架构：
	organizationalStructure: "组织架构",
	// 组织架构：
	AnhuiProvincialSituation: "安徽省情",
	// 资料下载：
	dataDownloading: "资料下载",
	// 常见问题：
	questionsAnswers: "常见问题",
	// 联系我们：
	contactUs: "联系我们",
	// 展馆布局

	exhibitionLayout: "展馆布局",
	// 世界制造业大会
	wmc: "世界制造业大会",
	//大会名称
	nameOfConference: "大会名称",
	//大会主题Theme of the conference
	themeOfConference: "大会主题",
	//参展嘉宾
	guestsAtExhibition: "参展嘉宾",
	//展览成效
	exhibitionEffectiveness: "展览成效",
	// 往届回顾
	review: "往届回顾",
	agenda: "议程",

	hostedBy: "主办单位",
	liveUrl: "直播回放",
	host: "主持人",
	pending: "待定",

};