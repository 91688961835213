import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    white: false,
    showMobileMenu: false,
    lang: window.sessionStorage.getItem("currLang")
      ? window.sessionStorage.getItem("currLang")
      : "zh-CN",
  },
  getters: {},
  mutations: {
    SET_WHITE_HEADER: (state, flag) => {
      state.white = flag;
    },
    SET_SHOW_MOBILE_MENU: (state) => {
      state.showMobileMenu = !state.showMobileMenu;
    },
    SET_LANG: (state, val) => {
      state.lang = val;
    },
  },
  actions: {},
  modules: {},
});
