<template>
  <div class="wmc-login-box">
    <el-form
      label-position="top"
      label-width="80px"
      :model="loginForm"
      :rules="rules"
      ref="ruleForm"
    >
      <div class="wmc-lblc-tips"></div>
      <el-form-item label="登录方式">
        <el-radio v-model="loginMethods" label="1">账号登录</el-radio>
        <el-radio v-model="loginMethods" label="2">短信验证码登录</el-radio>
      </el-form-item>
      <div v-if="loginMethods === '1'">
        <el-form-item label="用户名" prop="userName">
          <el-input
            v-model="loginForm.userName"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            show-password
            v-model="loginForm.password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="verificationCode">
          <el-input
            v-model="loginForm.verificationCode"
            placeholder="请输入验证码"
            class="wmc-register-yzm-left"
          ></el-input>
          <div class="wmc-register-yzm" id="yzm" style="display: inline-block">
            <img :src="verificationCodeSrc" alt="" />
          </div>
          <div class="wmc-register-yzm-right wmc-hover" id="hyg" @click="hyg">
            换一个
          </div>
        </el-form-item>
      </div>
      <div v-if="loginMethods === '2'">
        <el-form-item label="手机号" prop="phone">
          <el-input
            v-model="loginForm.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片验证码" prop="verificationCode1">
          <el-input
            v-model="loginForm.verificationCode1"
            placeholder="请输入验证码"
            class="wmc-register-yzm-left"
          ></el-input>
          <div class="wmc-register-yzm">
            <img :src="verificationCodeSrc" alt="" />
          </div>
          <div class="wmc-register-yzm-right wmc-hover" @click="hyg">
            换一个
          </div>
        </el-form-item>
        <el-form-item label="短信验证码" prop="code">
          <el-input
            v-model="loginForm.code"
            placeholder="请输入短信验证码"
            class="wmc-yzm-left"
          ></el-input>
          <div
            v-show="show"
            class="wmc-yzm-right wmc-hover"
            style="display: inline; float: right"
            @click="changeGet()"
          >
            获取验证码
          </div>
          <div
            v-show="!show"
            class="wmc-yzm-right wmc-hover"
            style="display: inline; float: right"
          >
            {{ count }}秒后重新获取
          </div>
        </el-form-item>
      </div>
    </el-form>
    <div
      class="wmc-login-btn clearfix wmc-hover"
      id="loginit"
      @click="login(loginMethods)"
    >
      登录
    </div>
  </div>
</template>
<script>
import global from "../global/global.js";
import Axios from "axios";
export default {
  name: "WmcCommonLogin",
  data() {
    return {
      displayFlag: false,
      canActive: true,
      count: "",
      show: true,
      timer: null,
      loginMethods: "1",
      verificationCodeSrc: require("../assets/images/wmc-yzm.png"),
      loginForm: {
        userName: "",
        password: "",
        verificationCode: "",
        verificationCode1: "",
        phone: "",
        code: "",
      },
      rules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        verificationCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        verificationCode1: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  mounted() {
    var uuid = this.getUUID();
    sessionStorage.setItem("uuid", uuid);
    this.getCaptcha(uuid);
    // 渲染登录界面
  },
  methods: {
    //获取uuid
    getUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    /**
     * 获取验证码
     */
    getCaptcha(uuid) {
      this.verificationCodeSrc =
        global.apiUrl + "/wmc-server/sys/captcha?uuid=" + uuid;
    },
    showYzm() {
      this.$message.error("请输入正确的图形验证码");
    },
    hyg() {
      var uuid = this.getUUID();
      sessionStorage.removeItem("uuid");
      sessionStorage.setItem("uuid", uuid);
      this.getCaptcha(uuid);
    },
    // getChange() {
    //   Axios.post(global.apiUrl + "/wmc-server/phoneLogin/verify", {
    //     captcha: this.loginForm.verificationCode1,
    //     phone: sessionStorage.getItem("uuid"),
    //   }).then((response) => {
    //     if (response.data.code === 200) {
    //       this.displayFlag = true;
    //     } else {
    //       this.$message.error(response.data.msg);
    //     }
    //   });
    // },
    login(num) {
      // 账号登录
      if (num === "1") {
        if (
          this.loginForm.userName === "" ||
          this.loginForm.password === "" ||
          this.loginForm.verificationCode === ""
        ) {
          this.$message.error("请输入必填项");
          return;
        }
        // 登录
        var password = this.loginForm.password;
        Axios.post(global.apiUrl + "/wmc-server/sys/login", {
          captcha: this.loginForm.verificationCode,
          password: global.SHA256(global.SHA256(password) + password),
          username: this.loginForm.userName,
          uuid: sessionStorage.getItem("uuid"),
        }).then((response) => {
          if (response.data.code === 200) {
            window.sessionStorage.removeItem("token");
            this.handlerRes(response);
            //  window.open("http://10.34.6.123:18066/wmc-ui/#/wmc/unit")
          } else {
            this.$message.error(response.data.msg);
            var uuid = this.getUUID();
            sessionStorage.removeItem("uuid");
            sessionStorage.setItem("uuid", uuid);
            this.getCaptcha(uuid);
            // $('#yzm').load(location.href+' #yzm')
          }
        });
      } else if (num === "2") {
        if (this.loginForm.phone === "" || this.loginForm.code === "") {
          this.$message.error("请输入必填项");
          return;
        }
        // 调用手机号码登录接口
        Axios.post(global.apiUrl + "/wmc-server/phoneLogin/phone", {
          phone: this.loginForm.phone,
          captcha: this.loginForm.code,
        }).then((response) => {
          if (response.data.code === 200) {
            window.sessionStorage.removeItem("token");
            this.handlerRes(response);
            //  window.open("http://10.34.6.123:18066/wmc-ui/#/wmc/unit")
          } else {
            this.$message.error(response.data.msg);
          }
        });
      }
    },
    // 获取手机验证码
    changeGet() {
      Axios.post(
        global.apiUrl + "/wmc-server/shortMessage/verifyPicAndSend",
        {
          loginMobile: this.loginForm.phone,
          shortMessageType: 1,
          uuid: sessionStorage.getItem("uuid"),
          captcha: this.loginForm.verificationCode1,
        }
        // eslint-disable-next-line no-unused-vars
      ).then((response) => {
        if (response.data.code === 200) {
          this.$message.success("发送成功");
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        } else {
          this.$message.error(response.data.msg);
          var uuid = global.getUUID();
          sessionStorage.removeItem("uuid");
          sessionStorage.setItem("uuid", uuid);
          this.$nextTick(() => {
            this.verificationCodeSrc = global.getCaptcha(uuid);
            this.loginForm.verificationCode1 = "";
            this.loginForm.verificationCode = "";
          });
        }
      });
    },
    // 登录成功跳转
    handlerRes(response) {
      let data = response.data.data;
      window.sessionStorage.setItem("token", data.token);
      document.cookie = "token=" + data.token;
      // Cookies.set('token',data.token)
      // 登录到具体的服务器登录入口
      // window.open(https://hw.wmconvention.com/#/login?token='+window.sessionStorage.getItem('token'))
      // window.open(
      //   "http://10.34.6.123:18066/wmc-ui/#/login?token=" +
      //     window.sessionStorage.getItem("token")
      // );
      window.open(
        "https://hw.wmconvention.com/#/login?token=" +
          window.sessionStorage.getItem("token")
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.wmc-login-box {
  padding: 0 0 100px;
  width: 600px;
  margin: 0 auto;
}
.wmc-lblc-tips {
  font-size: 16px;
  color: #858d90;
  text-align: center;
  margin-bottom: 24px;
}
.redStart {
  font-size: 20px;
  color: red;
  display: inline-block;
  line-height: 10px;
  position: relative;
  bottom: -4px;
}
.wmc-lblc-tips span {
  text-decoration: underline;
  cursor: pointer;
}
.wmc-login-label {
  font-size: 16px;
  color: #4d5759;
  height: 40px;
  line-height: 40px;
}
.wmc-yzm-left {
  width: calc(100% - 146px) !important;
  display: inline-block !important;
  vertical-align: top;
}
.wmc-yzm-right {
  display: inline-block;
  width: 146px;
  height: 46px;
  text-align: center;
  color: #0054a3;
  line-height: 46px;
  vertical-align: top;
}
.wmc-login-btn {
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  border-radius: 40px;
  margin: 46px 0;
  color: #fff;
  background: #0054a3;
  border: 1px solid transparent;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
}
.wmc-register-tips {
  font-size: 14px;
  color: #858d90;
  margin-top: 40px;
  text-align: center;
}
.wmc-register-yzm-left {
  width: calc(100% - 239px) !important;
  display: inline-block !important;
  vertical-align: top;
}
.wmc-register-yzm-right {
  display: inline-block;
  width: 90px;
  height: 46px;
  text-align: center;
  color: #0054a3;
  line-height: 46px;
  vertical-align: top;
  font-size: 16px;
}
.wmc-register-yzm {
  width: 129px;
  height: 46px;
  display: inline-block !important;
  vertical-align: top;
  margin-left: 20px;
}
.wmc-register-yzm img {
  width: 100%;
  // margin-top: 6px;
}

.register-box {
  display: none;
}
.phone-box {
  display: none;
}
input::-webkit-input-placeholder,
input::-moz-placeholder,
input:-moz-placeholder,
input:-ms-input-placeholder {
  color: #999;
}
@media screen and (max-width: 1366px) {
  .wmc-main-title {
    margin-top: 80px;
    font-size: 36px;
  }
  .wmc-main-title ul {
    margin: 16px auto 20px;
  }
  .wmc-lblc-tips {
    margin-bottom: 12px;
  }
  .layui-form-item {
    margin-bottom: 12px;
  }
  .layui-input,
  .layui-select,
  .layui-textarea,
  .wmc-yzm-right,
  .wmc-register-yzm-right,
  .wmc-register-yzm {
    height: 40px;
    line-height: 40px;
  }
  .wmc-login-btn {
    margin: 36px 0;
  }
  .wmc-register-tips {
    margin-top: -20px;
  }
}
/deep/ .el-form--label-top {
  .el-form-item__label {
    display: block;
    font-size: 16px;
    color: #4d5759;
    height: 40px;
    line-height: 32px;
    padding: 0;
    &::before {
      display: none;
    }
  }
  .el-input__inner {
    height: 46px;
    line-height: 46px;
    border-radius: 2px;
    &:hover,
    &:focus {
      border-color: #0055a3;
    }
  }
}
</style>
