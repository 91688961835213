<template>
  <div class="wmcScroll" ref="box">
    <div class="box-cont" ref="boxChild" :style="boxStyle">
      <slot></slot>
      <slot v-if="show"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "wmcScroll",
  props: {
    speed: {
      default: 1,
      type: Number,
    },
    direction: {
      type: String,
      default: "left",
    },
  },
  computed: {
    //第一个slot
    ele0() {
      return this.$refs.boxChild.children[0];
    },
    //第二个slot
    ele1() {
      return this.$refs.boxChild.children[1];
    },
    //盒子的可视高度
    boxHeight() {
      return this.$refs.box.clientHeight;
    },
    boxWidth() {
      return this.$refs.box.clientWidth;
    },
    flag1() {
      return this.direction == "left" ? this.boxWidth : this.boxHeight;
    },
    flag2() {
      return this.direction == "left"
        ? this.ele0.clientWidth
        : this.ele0.clientHeight;
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.direction == "left") {
        this.boxStyle = {
          width: this.$refs.boxChild.children[1].clientWidth * 2 + "px",
        };
      } else {
        this.boxStyle = {
          height: this.$refs.boxChild.children[1].clientHeight * 2 + "px",
        };
      }
      //在盒子内容高度小于可视高度时不滚动
      if (this.flag1 < this.flag2) {
        this.start(this.step);
        this.setEvet();
        this.show = true;
      } else {
        this.isScroll = false;
        this.show = false;
      }
    }, 500);
  },
  destroyed() {
    this.isScroll = false;
  },
  data() {
    return {
      show: true,
      height: 0,
      step: 0,
      isScroll: true,
      boxStyle: {},
      boxChildHeight: {},
    };
  },
  methods: {
    //鼠标移入停止滚动 移出继续滚动
    setEvet() {
      this.$refs.box.onmouseenter = () => {
        this.isScroll = false;
        // this.height = 0;
      };
      this.$refs.box.onmouseleave = () => {
        this.isScroll = true;
        this.$nextTick(() => {
          this.start(this.step);
          //   this.start(this.height);
        });
      };
    },
    //滚动方法
    // start(height) {
    //   this.ele0.style = `transform:translateY(-${height}px);`;
    //   this.ele1.style = `height:${this.boxHeight}px;transform:translateY(-${height}px);overflow: hidden;`;
    //   if (height >= this.ele0.clientHeight) {
    //     this.height = 0;
    //   } else {
    //     this.height += this.speed;
    //   }
    //   if (!this.isScroll) return;
    //   window.requestAnimationFrame(() => {
    //     this.start(this.height);
    //   });
    // },
    start(step) {
      if (this.direction == "left") {
        this.ele0.style = `transform:translateX(-${step}px);`;
        this.ele1.style = `width:${this.boxWidth}px;transform:translateX(-${step}px);`;
        // this.ele1.style = `width:${this.boxWidth}px;transform:translateX(-${step}px);overflow: hidden;`;
        if (step >= this.ele0.clientWidth) {
          this.step = 0;
        } else {
          this.step += this.speed;
        }
      } else if (this.direction == "up") {
        this.ele0.style = `transform:translateY(-${step}px);`;
        this.ele1.style = `width:${this.boxHeight}px;transform:translateY(-${step}px);`;
        // this.ele1.style = `width:${this.boxHeight}px;transform:translateY(-${step}px);overflow: hidden;`;
        if (step >= this.ele0.clientHeight) {
          this.step = 0;
        } else {
          this.step += this.speed;
        }
      }

      if (!this.isScroll) return;
      window.requestAnimationFrame(() => {
        this.start(this.step);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wmcScroll {
  overflow: hidden;
}
.hover {
  overflow: auto;
}
.hide {
  display: none;
}
</style>
