<template>
  <div class="banner">
    <div class="banner-title">
      <span>{{ title }}</span>
      <span v-if="entitle && lang === 'zh-CN'" class="entitle">{{
        entitle
      }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "WmcTitleBanner",
  props: ["title", "entitle"],
  data() {
    return {};
  },
  computed: {
    lang() {
      return this.$store.state.lang;
    },
  },
};
</script>
<style lang="scss" scoped>
.banner {
  height: 180px;
  width: 100%;
  background: url("../assets/images/banner/banner-two.png") no-repeat;
  background-size: 100% 100% !important;
  .banner-title {
    box-sizing: border-box;
    width: 1200px;
    margin: 0 auto;
    font-size: 36px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 180px;
  }
  .entitle {
    font-size: 22px;
    font-family: PingFang SC-Light, PingFang SC;
    font-weight: 300;
    color: #ffffff;
  }
}
</style>
