<!-- 敬请期待组件 -->
<template>
  <div class="wmc-empty">
    <img src="../assets/images/img-jqqd.png" alt="" />
    <div>敬请期待...</div>
  </div>
</template>
<script>
export default {
  name: "WmcEmpty",
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.wmc-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    height: 80x;
    text-align: center;
    font-size: 32px;
    color: #999;
    line-height: 80px;
  }
}
</style>
