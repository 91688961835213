import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import "@/assets/styles/common.scss";
import "video.js/dist/video-js.css";

// 引入防抖
import Debounce from "@/global/debounceAndThrottle.js";
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("Debounce", Debounce);

// 全局引入global
import global from "@/global/global.js";
Vue.prototype.global = global;

// 大标题组件
import WmcTitle from "@/components/WmcTitle.vue";
// 敬请期待组件
import WmcEmpty from "@/components/WmcEmpty.vue";
// 滚动组件
import wmcScroll from "@/components/wmcScroll.vue";
// 标题banner
import WmcTitleBanner from "@/components/WmcTitleBanner.vue";
// 分页组件
import WmcPagination from "@/components/WmcPagination.vue";
// 往届回顾
import PreviousReviews from "@/components/PreviousReviews.vue";
// 常规登录
import WmcCommonLogin from "@/components/WmcCommonLogin";

//引入中英文切换插件vue-i18n
import VueI18n from "vue-i18n";
Vue.use(VueI18n); // 挂载
const i18n = new VueI18n({
	locale: window.sessionStorage.getItem("currLang") ?
		window.sessionStorage.getItem("currLang") : "zh-CN", // 语言标识
	messages: {
		"zh-CN": require("./lang/zh"), // 通过require引入中文语言包
		"en-US": require("./lang/en"), // 通过require引入英文语言包
	},
});

// 懒加载
import VueLazyLoad from "vue-lazyload";
Vue.use(VueLazyLoad, {
	error: "", //报错时需要的图片
	loading: "", //加载时需要的图片
});

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview)

Vue.component(WmcTitle.name, WmcTitle);
Vue.component(WmcEmpty.name, WmcEmpty);
Vue.component(wmcScroll.name, wmcScroll);
Vue.component(WmcTitleBanner.name, WmcTitleBanner);
Vue.component(WmcPagination.name, WmcPagination);
Vue.component(PreviousReviews.name, PreviousReviews);
Vue.component(WmcCommonLogin.name, WmcCommonLogin);

Vue.directive("focus", {
	inserted: (el, binding) => {
		if (binding.value == true || binding.value == undefined) {
			el.focus();
			el.querySelector("input").focus();
		}
	},
});

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount("#app");