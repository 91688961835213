import Vue from "vue";
import {
	Message
} from "element-ui";
import "element-ui/lib/theme-chalk/message.css";
import VueRouter from "vue-router";
// import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);
import Layout from "@/components/layout/index";

const routes = [
	// {
	//   path: "/",
	//   redirect: "/index",
	// },
	{
		path: "/",
		component: Layout,
		redirect: "/index",
		meta: {
			title: "首页",
			icon: "icon5"
		},
		children: [{
				path: "index",
				name: "index",
				component: () => import("@/views/Index/index"),
				meta: {
					title: "首页"
				},
			},
			{
				path: "test",
				name: "test",
				component: () => import("@/views/Index/test"),
				meta: {
					title: "测试页面"
				},
			},
			{
				path: "newsCenter",
				name: "newsCenter",
				component: () => import("@/views/newsCenter/index"),
				meta: {
					title: "资讯中心"
				},
			},
			// 资讯中心，文字资讯更多列表
			{
				path: "moreNews",
				name: "moreNews",
				component: () => import("@/views/newsCenter/moreNews"),
				meta: {
					title: "资讯中心"
				},
			},
			// 资讯中心，精彩瞬间更多
			{
				path: "moreNewsImg",
				name: "moreNewsImg",
				component: () => import("@/views/newsCenter/moreNewsImg"),
				meta: {
					title: "资讯中心"
				},
			},
			// 新闻中心，文字新闻，新闻详情
			{
				path: "newsDetail",
				name: "newsDetail",
				component: () => import("@/views/newsCenter/newsDetail"),
				meta: {
					title: "资讯中心"
				},
			},
			// 论坛活动
			{
				path: "wmcActivities",
				name: "wmcActivities",
				component: () => import("@/views/wmcActivities/index"),
				meta: {
					title: "大会活动"
				},
			},
			// 活动详情
			{
				path: "livingDetail",
				name: "livingDetail",
				component: () => import("@/views/wmcActivities/livingDetail"),
				meta: {
					title: "大会活动"
				},
			},
			{
				path: "activeDetail",
				name: "activeDetail",
				component: () => import("@/views/wmcActivities/activeDetail"),
				meta: {
					title: "论坛活动"
				},
			},
			// 活动详情
			{
				path: "publishDetail",
				name: "publishDetail",
				component: () => import("@/views/wmcPublish/publishDetail"),
				meta: {
					title: "大会发布"
				},
			},
			// 展览展示
			{
				path: "wmcShow",
				name: "wmcShow",
				component: () => import("@/views/wmcShow/index"),
				meta: {
					title: "大会展示"
				},
			},
			// 展览展示-更多列表
			{
				path: "wmcShowList",
				name: "wmcShowList",
				component: () => import("@/views/wmcShow/list"),
				meta: {
					title: "大会展示"
				},
			},
			// 展览展示-详情
			{
				path: "wmcShowDetail",
				name: "wmcShowDetail",
				component: () => import("@/views/wmcShow/detail"),
				meta: {
					title: "大会展示"
				},
			},
			// 发布活动
			{
				path: "wmcPublish",
				name: "wmcPublish",
				component: () => import("@/views/wmcPublish/index"),
				meta: {
					title: "大会发布"
				},
			},
			// 供需对接
			// {
			//   path: "supplyDemandDocking",
			//   name: "supplyDemandDocking",
			//   component: () => import("@/views/supplyDemandDocking/index"),
			//   meta: { title: "供需对接" },
			// },
			// 线上展会
			// {
			//   path: "cloudShow",
			//   name: "cloudShow",
			//   component: () => import("@/views/cloudShow/index"),
			//   meta: { title: "线上展会" },
			// },
			// 云开幕
			// {
			//   path: "cloudOpening",
			//   name: "cloudOpening",
			//   component: () => import("@/views/cloudShow/CloudOpening"),
			//   meta: { title: "线上展会" },
			// },
			// 云论坛
			// {
			//   path: "cloudForum",
			//   name: "cloudForum",
			//   component: () => import("@/views/cloudShow/CloudForum"),
			//   meta: { title: "线上展会" },
			// },
			// 云发布
			// {
			//   path: "cloudPublish",
			//   name: "cloudPublish",
			//   component: () => import("@/views/cloudShow/CloudPublish"),
			//   meta: { title: "线上展会" },
			// },
			// 大会服务
			{
				path: "wmcService",
				name: "wmcService",
				component: () => import("@/views/wmcService/index"),
				meta: {
					title: "大会服务"
				},
			},
			// 关于
			{
				path: "wmcAbout",
				name: "wmcAbout",
				component: () => import("@/views/wmcAbout/index"),
				meta: {
					title: "关于大会"
				},
			},
			// 登录入口
			// {
			//   path: "wmcEntrance",
			//   name: "wmcEntrance",
			//   component: () => import("@/views/wmcEntrance/index"),
			//   meta: { title: "登录入口" },
			// },
			// 登录入口 2023
			{
				path: "wmcEntranceNew",
				name: "wmcEntranceNew",
				component: () => import("@/views/wmcEntranceNew/index"),
				meta: {
					title: "登录入口"
				},
			},
			// 登录
			// {
			//   path: "wmcLogin",
			//   name: "wmcLogin",
			//   component: () => import("@/views/wmcLogin/index"),
			//   meta: { title: "登录", withOutFooter: true },
			// },
			// 参展商注册登录
			// {
			//   path: "wmcExbition",
			//   name: "wmcExbition",
			//   component: () => import("@/views/wmcExbition/index"),
			//   meta: { title: "参展商注册登录登录", withOutFooter: true },
			// },
			// 观众注册登录
			// {
			//   path: "wmcAudience",
			//   name: "wmcAudience",
			//   component: () => import("@/views/wmcAudience/index"),
			//   meta: { title: "观众注册登录", withOutFooter: true },
			// },
			// 2023 注册
			{
				path: "wmcRegister",
				name: "wmcRegister",
				component: () => import("@/views/wmcRegister/index"),
				meta: {
					title: "注册",
					withOutFooter: true
				},
			},
			{
				path: "wmcFinsh",
				name: "wmcFinsh",
				component: () => import("@/views/wmcFinsh/index"),
				meta: {
					title: "注册完成",
					withOutFooter: true
				},
			},
			{
				path: "wmcAlipay",
				name: "wmcAlipay",
				component: () => import("@/views/wmcAlipay/index"),
				meta: {
					title: "支付宝"
				},
			},
			// 专业观众注册登录
			// {
			//   path: "wmcAudiencePro",
			//   name: "wmcAudiencePro",
			//   component: () => import("@/views/wmcAudience/audiencePro"),
			//   meta: { title: "专业观众注册登录", withOutFooter: true },
			// },
			// 搜索结果
			{
				path: "wmcSearch",
				name: "wmcSearch",
				component: () => import("@/views/wmcSearch/index"),
				meta: {
					title: "搜索结果"
				},
			},
		],
	},
];

const router = new VueRouter({
	routes,
});
// 滚动条保持在顶部
// router.beforeEach((to, from, next) => {
//   // document.body.scrollTop = 0;
//   next();
//   if (!document.getElementsByClassName("el-scrollbar__wrap")[0]) return;
//   document.getElementsByClassName("el-scrollbar__wrap")[0].scrollTop = 0;
// });
//push
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
	return VueRouterPush.call(this, to).catch((err) => err);
};

//replace
const VueRouterReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(to) {
	return VueRouterReplace.call(this, to).catch((err) => err);
};

router.beforeEach(async (to, from, next) => {
	if (to.path) {
		if (window._hmt) {
			window._hmt.push(["_trackPageview", "/#" + to.fullPath]);
		}
	}
	//const menu = ["/cloudShow", "/wmcShow","/wmcPublish"];
	//	const menu = ["/cloudShow", "/wmcPublish"];
	const menu = ["/cloudShow"];
	// const menu = ["/cloudShow"];
	if (menu.indexOf(to.path) != -1) {
		Message({
			showClose: true,
			message: "敬请期待",
			customClass: "my-message",
		});
	} else {
		next();
		if (!document.getElementsByClassName("el-scrollbar__wrap")[0]) return;
		document.getElementsByClassName("el-scrollbar__wrap")[0].scrollTop = 0;
	}
});

export default router;