<template>
  <div class="wmc-main-title">
    <div>{{ title }}</div>
    <img
      class="title-img"
      src="../assets/images/banner/after_icon.png"
      alt=""
    />
    <!-- <ul>
      <li class="li1"></li>
      <li class="li2"></li>
      <li class="li3"></li>
    </ul> -->
    <slot></slot>
    <router-link v-if="morePath" :to="morePath">
      <div class="know-more">了解更多</div>
    </router-link>
  </div>
</template>
<script>
export default {
  name: "WmcTitle",
  props: ["title", "morePath"],
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.wmc-main-title {
  font-size: 40px;
  color: #000;
  margin-top: 80px;
  text-align: center;
  position: relative;

  > img {
    margin: 20px auto 40px;
  }
  ul {
    font-size: 0;
    margin: 20px auto 40px;
    li {
      display: inline-block;
      vertical-align: text-top;
      height: 4px;
    }
    .li1 {
      width: 10px;
      background-color: #e5006a;
    }
    .li2 {
      width: 28px;
      background-color: #1eb9ee;
    }
    .li3 {
      width: 36px;
      background-color: #0055a3;
    }
  }
  .know-more {
    position: absolute;
    font-size: 14px;
    color: #666;
    right: 0;
    top: 24px;
    cursor: pointer;
    user-select: none;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 1;
    }
  }
}
</style>
