<template>
  <div class="wmc-sideBar">
    <div class="wmc-sideBar-chat">
      <div
        v-for="(item, index) in sideBarList"
        :key="index"
        class="wmc-sbc-item"
      >
        <div class="wmc-hover wmc-sc-ewm-icon">
          <img :src="item.iconSrc" :alt="item.text + '图标'" />
        </div>
        <div class="wmc-tt-ewm wmc-sc-ewm">
          <img :src="item.imgSrc" :alt="item.text + '二维码'" />
        </div>
      </div>
    </div>
    <div class="wmc-sideBar-top wmc-hover" @click="goTop"></div>
  </div>
</template>
<script>
export default {
  name: "side-bar",
  data() {
    return {
      sideBarList: [
        {
          iconSrc: require("../assets/images/banner/icon-xcx.png"),
          imgSrc: require("../assets/images//wmc-xcx.jpg"),
          text: "小程序",
        },
        {
          iconSrc: require("../assets/images/banner/icon-zfb.png"),
          imgSrc: require("../assets/images//wmc-zfb.png"),
          text: "支付宝",
        },
        {
          iconSrc: require("../assets/images/banner/icon-wx.png"),
          imgSrc: require("../assets/images/wmc-wx.jpeg"),
          text: "微信",
        },
        {
          iconSrc: require("../assets/images/banner/icon-wb.png"),
          imgSrc: require("../assets/images/wmc-wb.png"),
          text: "微博",
        },
        {
          iconSrc: require("../assets/images/banner/icon-tt.png"),
          imgSrc: require("../assets/images/wmc-tt.jpg"),
          text: "头条",
        },
        {
          iconSrc: require("../assets/images/banner/icon-dy.png"),
          imgSrc: require("../assets/images/wmc-dy.jpg"),
          text: "抖音",
        },
        {
          iconSrc: require("../assets/images/banner/icon-bjh.png"),
          imgSrc: require("../assets/images/wmc-bjh.png"),
          text: "百家号",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    goTop() {
      this.$emit("goTop");
    },
  },
};
</script>
<style lang="scss" scoped>
.wmc-sideBar {
  width: 90px;
  position: fixed;
  bottom: 90px;
  right: 60px;
  cursor: pointer;
  z-index: 9;
  .wmc-sideBar-chat {
    border: 1px solid transparent;
    border-radius: 6px;
    background: #fff;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    position: relative;
    .wmc-sbc-item {
      height: 80px;
      line-height: 80px;
      text-align: center;
      position: relative;
      .wmc-sc-ewm-icon {
        img {
          width: 90px;
          height: 90px;
          vertical-align: middle;
        }
      }
      .wmc-sc-ewm {
        position: absolute;
        width: 150px;
        height: 150px;
        right: 100px;
        background-color: #fff;
        top: 0;
        margin-top: -36px;
        display: none;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -ms-transition: all 0.2s;
        -o-transition: all 0.2s;
        z-index: -1;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &:hover {
        .wmc-sc-ewm {
          display: block !important;
          right: 100px;
        }
      }
    }
  }
  .wmc-sideBar-top {
    margin-top: 16px;
    height: 60px;
    width: 90px;
    border: 1px solid #f1f1f1;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background: url("../assets/images/icon-top.png") no-repeat #fff;
    background-position: 50% 50%;
    box-sizing: border-box;
    margin-left: 1px;
  }
}
</style>
