<!-- 往届回顾 -->
<template>
  <div :class="['wmc-previous-reviews', darkBg ? 'dark-bg' : '']">
    <div class="wmc-pr-tab">
      <ul class="wmc-prt-cont">
        <li
          :class="{ 'wmc-prtc-active': previousReviews === 0 }"
          @click="previousReviews = 0"
        >
          2018
        </li>
        <li
          :class="{ 'wmc-prtc-active': previousReviews === 1 }"
          @click="previousReviews = 1"
        >
          2019
        </li>
        <li
          :class="{ 'wmc-prtc-active': previousReviews === 2 }"
          @click="previousReviews = 2"
        >
          2020
        </li>
        <li
          :class="{ 'wmc-prtc-active': previousReviews === 3 }"
          @click="previousReviews = 3"
        >
          2021
        </li>
      </ul>
    </div>
    <div class="wmc-pr-cont">
      <div class="wmc-prc-item" v-if="previousReviews === 0">
        <div class="wmc-prci-left">
          <img src="../assets/images/wmc-wjhg-2018.jpg" alt="" />
        </div>
        <div class="wmc-prci-right">
          <el-scrollbar style="height: 100%">
            <div class="wmc-prcir-tit">
              大会名称：<span class="wmc-prcit-bold">2018世界制造业大会</span>
            </div>
            <div class="wmc-prcir-tit">
              大会主题：<span class="wmc-prcit-bold"
                >“创新驱动、制造引领、拥抱世界新工业革命”</span
              >
            </div>
            <div class="wmc-prcir-tit">
              <span class="wmc-prcit-bold">客商规模</span>
            </div>
            参会嘉宾超过4000位，其中100多位境内外世界500强高管、近500家国内
            外制造业领军知名企业负责人、30多位“两院”院士专家学者参会。
            <div class="wmc-prcir-tit">
              <span class="wmc-prcit-bold">展区规模</span>
            </div>
            展览展示面积为43000平方米。主展馆设有综合展、国内智能制造业展、国际
            智能制造业展、金融服务业；9号馆设有安徽人力资源展区；10号馆设有安徽进出口商品
            精品展。
            <div class="wmc-prcir-tit">
              <span class="wmc-prcit-bold">成果展示</span>
            </div>
            大会共签约合作项目436个、投资总额4471亿元，涵盖电子信息和家电、新
            材料和新能源、装备制造、节能环保、现代服务、汽车和汽车零部件、生物医药等9个行
            业。
          </el-scrollbar>
        </div>
      </div>
      <div class="wmc-prc-item" v-else-if="previousReviews === 1">
        <div class="wmc-prci-left">
          <img src="../assets/images/wmc-wjhg-2019.jpg" alt="" />
        </div>
        <div class="wmc-prci-right">
          <el-scrollbar style="height: 100%">
            <div class="wmc-prcir-tit">
              大会名称：<span class="wmc-prcit-bold">2019世界制造业大会</span>
            </div>
            <div class="wmc-prcir-tit">
              大会主题：<span class="wmc-prcit-bold"
                >“创新创业创造，迈向制造业新时代”</span
              >
            </div>
            <div class="wmc-prcir-tit">
              <span class="wmc-prcit-bold">客商规模</span>
            </div>
            78个国家和地区4500多位嘉宾出席，其中境外来宾1700多位，111家境内外世界500强负责人、30多位知名专家学者参会，伊拉克总理率60人组成的政府代表团参加会议。
            <div class="wmc-prcir-tit">
              <span class="wmc-prcit-bold">展区规模</span>
            </div>
            展览展示注重前沿性、智能性、体验性，展区面积达6.1万平方米，分为序厅、国际制造、智能制造、高端制造、绿色制造、服务型制造、数字经济、无人驾驶汽车体验等十大展区。
            <div class="wmc-prcir-tit">
              <span class="wmc-prcit-bold">成果展示</span>
            </div>
            大会集中签约项目638个，投资总额达7351亿元，项目数、投资总额分别较去年大会增长46%和64%。其中，制造业项目数、投资额分别占总量的87%、86%，先进制造业项目、投资额分别占总量的49%、68%。签约项目涵盖集成电路、新型显示、智能终端、机器人、通用航空、轨道交通、新材料、生物医药、新能源、新能源汽车、节能环保等行业。
          </el-scrollbar>
        </div>
      </div>
      <div class="wmc-prc-item" v-else-if="previousReviews === 2">
        <div class="wmc-prci-left">
          <img src="../assets/images/wmc-wjhg-2020.jpg" alt="" />
        </div>
        <div class="wmc-prci-right">
          <el-scrollbar style="height: 100%">
            <div class="wmc-prcir-tit">
              大会名称：<span class="wmc-prcit-bold"
                >2020 年世界制造业大会江淮线上经济论坛</span
              >
            </div>
            <div class="wmc-prcir-tit">
              大会主题：<span class="wmc-prcit-bold"
                >“线上经济赋能高质量发展”</span
              >
            </div>
            <div class="wmc-prcir-tit">
              <span class="wmc-prcit-bold">参会客商</span>
            </div>
            大会通过线上线下相结合的方式，举办云开幕暨主旨论坛、云签约、云论坛、云展示等“四朵云”系列活动。邀请全球制造业和数字经济等领域最具影响力的企业家、科学家，云上或现场出席会议。
            <div class="wmc-prcir-tit">
              <span class="wmc-prcit-bold">展览情况</span>
            </div>
            本次大会论坛首次采用线上线下结合形式举办，通过手机、电脑登录云平台，即可实现云端参会、线上观展。大会创新展示方式，将传统的线下展览转变为24小时线上呈现，依托数字网络和融媒体等现代信息技术手段，通过图文、视频、3D立体模型导航等形式，突出展示安徽科技创新能力，重点展示“芯屏器合”等安徽制造业高质量发展成就，集中展示长三角一体化发展成果，全面展示全省220多个骨干企业及其特色产品，并将持续运行1个月，引导线下互动交流及对接合作。
            <div class="wmc-prcir-tit">
              <span class="wmc-prcit-bold">成果展示</span>
            </div>
            大会论坛共签约项目678个、投资总额6178亿元，其中代表制造业未来发展方向的战略性新兴产业项目288个、投资额2848
            亿元，分别占42.5%、46.1%，较去年大会增长38.5%、59.6%，为安徽制造业转型升级注入了新动能。大会论坛期间，还先后发布了《长三角制造业协同发展报告》《长三角地区电子证照互认应用合作共识》《数据赋能政府治理评价及安徽省发展研究报告》《安徽省5G+工业互联网十大创新应用》《工业互联网和智能制造研究白皮书》《徽商发展报告2020》以及“2020创新力徽商”和“皖事通办·一源五端”等相关成果。
          </el-scrollbar>
        </div>
      </div>
      <div class="wmc-prc-item" v-else>
        <div class="wmc-prci-left">
          <img src="../assets/images/wmc-wjhg-2021.png" alt="" />
        </div>
        <div class="wmc-prci-right">
          <el-scrollbar style="height: 100%">
            <div class="wmc-prcir-tit">
              大会名称：
              <span class="wmc-prcit-bold">2021世界制造业大会</span>
            </div>
            <div class="wmc-prcir-tit">
              大会主题：<span class="wmc-prcit-bold"
                >“创新驱动 数字赋能 携手全球制造业高质量发展”</span
              >
            </div>
            <div class="wmc-prcir-tit">
              <span class="wmc-prcit-bold">参会嘉宾</span>
            </div>
            国家部委、兄弟省（区、市）、驻华使节、国际组织、境外世界500强、央企、民企、商协会及院士和知名专家等嘉宾参会，其中外方代表主要来自德、日、韩、法、美、俄等24个国家和地区，通过线上线下方式参加。韩国为大会主宾国，沪苏浙为大会主宾省。围绕新兴产业和行业龙头企业精准务实邀商，共邀请了十大新兴产业领域企业近350家、世界500强企业60多家、上市公司70多家参会。
            <div class="wmc-prcir-tit">
              <span class="wmc-prcit-bold">展览成效</span>
            </div>
            按照政府引导、市场化运作的方式，会同省十大新兴产业工作专班，组织省内外428家行业优势企业参展，集中展示制造业新产品新技术新业态和十大新兴产业“双招双引”成效,
            其中省外企业参展比例超过20%以上。华为公司首发“华为工业云智能体”的工业互联网平台方案。主动承接进博会溢出效应，40多家进博会参展企业齐聚进口商品展区，推出一系列高端前沿产品。上海虹桥品汇展出来自26个国家和地区的198个日用消费品类，深受广大市民喜爱。展馆专门设立洽谈区，为境内外、省内外企业提供洽谈服务、搭建交流平台。
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "previous-reviews",
  props: {
    darkBg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      previousReviews: 0,
    };
  },
};
</script>
<style lang="scss" scoped>
.wmc-previous-reviews {
  height: 470px;
}
.wmc-pr-tab {
  border-bottom: 1px solid #dadada;
  box-sizing: border-box;
}
.wmc-prt-cont {
  margin: 0 auto;
  font-size: 0;
  height: 50px;
}
.wmc-prt-cont li {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  font-size: 24px;
  box-sizing: border-box;
  color: #333;
  text-align: center;
  position: relative;
  height: 100%;
  cursor: pointer;
}
.wmc-prt-cont li::after {
  display: inline-block;
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  width: 10px;
  height: 10px;
  background: #d8d8d8;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.wmc-prt-cont li:hover {
  color: #333;
  font-weight: bold;
}
.wmc-prt-cont li:hover::after {
  border: 5px solid #387ab6;
  margin-left: -10px;
  bottom: -10px;
  background: #fff !important;
}
.wmc-prtc-active {
  color: #333 !important;
  font-weight: bold;
}
.wmc-prtc-active::after {
  border: 5px solid #387ab6;
  margin-left: -10px !important;
  bottom: -10px !important;
  background: #fff !important;
}
.wmc-pr-cont {
  height: calc(100% - 90px);
  position: relative;
  margin-top: 40px;
}
.wmc-prc-item {
  font-size: 0;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  /* display: none; */
  z-index: 1;
}
.wmc-prc-item img {
  height: 380px;
}
.wmc-prc-item img + img {
  margin-left: 24px;
}
.wmc-prci-left {
  position: absolute;
  left: 0;
  top: 0;
  width: 580px;
  height: 380px;
}
.wmc-prci-left img {
  width: 100%;
}
.wmc-prci-right {
  position: absolute;
  left: 610px;
  top: 0;
  right: 0;
  height: 380px;
  font-size: 16px;
  word-spacing: normal;
  word-break: break-all;
  /* padding-top: 16px; */
  overflow: hidden;
  color: #333;
  padding-right: 18px;
}
.wmc-prcir-tit {
  line-height: 38px;
}
.wmc-prcir-tit span {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
.dark-bg {
  height: auto;
  .wmc-pr-tab {
    border-bottom: 1px solid #387ab6;
    .wmc-prt-cont {
      li {
        color: rgba(255, 255, 255, 0.8);
        &:hover {
          color: #fff;
        }
      }
      .wmc-prtc-active {
        color: #fff !important;
      }
    }
  }
  .wmc-pr-cont {
    /* overflow: auto; */
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    margin-top: 0;
    .wmc-prc-item {
      width: 1200px;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -600px;
      right: 0;
      bottom: 0;
      .wmc-prci-right {
        color: rgba(255, 255, 255, 0.8);
        .wmc-prcir-tit span {
          color: #fff;
        }
      }
    }
  }
}
</style>
