<template>
	<div class="wmc-global-menu">
		<dl v-for="(item, index) in menuList" :key="index">
			<div v-if="item.isSearch && lang == 'zh-CN'">
				<dt>
					<a href="javascript:void(0);">搜索</a>
				</dt>
				<dd>
					<div class="wmc-mobile-header-search-input">
						<input type="text" placeholder="请输入关键词" v-model="keysWord" @keyup.enter="toSearch" @blur="toSearch" />
						<i class="wmc-mobile-header-search-btn" @click="toSearch"></i>
					</div>
				</dd>
			</div>
			<dt v-else>
				<a href="javascript:void(0);" v-if="item.name === '大会看板'"
					@click="closeMobileMenuKanban(item.path)">{{ item.title }}</a>
				<a v-else href="javascript:void(0);" @click="closeMobileMenu(item.path)">{{ item.title }}</a>
			</dt>
		</dl>
	</div>
</template>
<script>
	// import { Message } from "element-ui";
	import "element-ui/lib/theme-chalk/message.css";
	export default {
		name: "mobile-menu",
		data() {
			return {
				keysWord: "",
				// 其他语言下展示的导航
				enMenuItem: [
					"/index",
					"/wmcActivities",
					"/wmcService",
					"/wmcAbout",
					"/wmcAlipay",
					"https://board.wmconvention.com/h5/#/h5/pages/index/index?local=zh-CN",
				],
			};
		},
		computed: {
			menuList() {
				const menuItems = [{
						path: "/index",
						title: this.$t("language.index")
					},
					{
						path: "/newsCenter",
						title: "资讯中心"
					},
					{
						path: "/wmcActivities",
						title: this.$t("language.conventionActivities"),
					},
					{
						path: "/wmcShow",
						title: "大会展示"
					},
					{
						path: "/wmcPublish",
						title: "大会发布"
					}, // { path: "/supplyDemandDocking", title: "供需对接" }, // { path: "/cloudShow", title: "线上展会" },
					{
						path: "/wmcService",
						title: this.$t("language.conventionServices")
					},
					// {
					//   name: "大会看板",
					//   path: "https://board.wmconvention.com/h5/#/h5/pages/index/index?local=zh-CN",
					//   title: this.$t("language.conferenceSignage"),
					// },
					{
						path: "/wmcAbout",
						title: this.$t("language.about")
					},
					{
						path: "/",
						title: "搜索",
						isSearch: true
					},
					{
						path: "/wmcAlipay",
						name: "支付指南",
						title: this.$t("language.Alipay"),
					},
				];
				return menuItems.filter((ele) => this.enMenuItem.includes(ele.path));
				// if (this.lang === "zh-CN") {
				// 	return menuItems.filter((item) => item.path !== "/wmcAlipay");
				// } else {
				// 	return menuItems.filter((ele) => this.enMenuItem.includes(ele.path));
				// }
			},
			lang() {
				return this.$store.state.lang;
			},
		},
		methods: {
			closeMobileMenuKanban(path) {
				if (this.lang !== "zh-CN") {
					window.location.href =
						"https://board.wmconvention.com/h5/#/h5/pages/index/index?local=en-US";
				} else {
					window.location.href = path;
				}

				this.$store.commit("SET_SHOW_MOBILE_MENU");
				// Message({
				//   showClose: true,
				//   message: "敬请期待",
				//   customClass: "my-message",
				// });
			},
			closeMobileMenu(path) {
				this.$router.push(path);
				this.$store.commit("SET_SHOW_MOBILE_MENU");
			},
			toSearch() {
				this.$router.push({
					name: "wmcSearch",
					params: {
						searchWord: this.keysWord
					},
				});
				this.keysWord = "";
				this.$store.commit("SET_SHOW_MOBILE_MENU");
			},
		},
	};
</script>
<style lang="scss" scoped>
	.wmc-global-menu {
		//   display: none;
		position: fixed;
		top: 64px;
		right: 0;
		left: 0;
		bottom: 0;
		background-color: #fff;
		z-index: 10;
		overflow: auto;
	}

	.wmc-global-menu dl {
		font-size: 16px;
		border-bottom: 1px solid #e5e5e5;
		padding: 0 20px;
	}

	.wmc-global-menu dl dt {
		line-height: 50px;
		font-weight: 700;
	}

	.wmc-global-menu dl dt a {
		color: #333;
	}

	.wmc-global-menu dl dd {
		line-height: 40px;
	}

	.wmc-global-menu dl a {
		display: block;
	}

	.wmc-mobile-header-search-input {
		height: 36px;
		line-height: 36px;
		width: 100%;
		box-sizing: border-box;
		position: relative;
		margin-bottom: 20px;
	}

	.wmc-mobile-header-search-input input {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		padding: 0 36px 0 20px;
		color: #333;
		outline: none;
		border: none;
		font-size: 16px;
		box-sizing: border-box;
		background: #fff;
		border: 1px solid #dadada;
		border-radius: 20px;
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		-ms-border-radius: 20px;
		-o-border-radius: 20px;
	}

	.wmc-mobile-header-search-btn {
		display: inline-block;
		width: 14px;
		height: 14px;
		background: url("../assets/images/icon-search-white.png") no-repeat;
		position: absolute;
		top: 50%;
		margin-top: -6px;
		right: 12px;
		cursor: pointer;
	}
</style>