<!--
 * @Description: 
 * @Author: jayfeec
 * @Date: 2022-08-20 17:49:35
 * @LastEditTime: 2022-09-14 20:49:05
 * @LastEditors: jayfeec
 * @Site: 
-->
<template>
  <div class="wmc-news-pagination">
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :pager-count="pagerCount"
      :page-size="pageSize"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: "WmcPagination",
  props: {
    total: {
      type: Number,
      default: 100,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      pagerCount: 5,
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit("currPageChange", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.wmc-news-pagination {
  text-align: center;
  margin: 28px 0 68px;
  /deep/ .el-pagination {
    .el-pager {
      li {
        padding: 0 16px;
        background: #fff;
        height: 40px;
        line-height: 40px;
        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        color: #262626;
        font-weight: normal;

        &:not(.disabled):hover {
          color: #0054a6;
        }
        &:not(.disabled).active {
          background-color: #0054a6;
          border: 1px solid #0054a6;
          &:hover {
            color: #fff;
          }
        }
      }
    }
    .btn-prev,
    .btn-next {
      width: 40px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      color: #262626;
      background: #fff;
    }
  }
}
</style>
