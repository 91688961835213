<template>
	<div id="wmc-header" :class="['wmc-header', white ? 'wmc-header-white' : '']">
		<div>

			<div class="wmc-header-logo">
				<router-link to="/" v-if="!white">
					<img src="../../assets/images/wmc-logo.svg" height="68px" alt="" />
				</router-link>
				<router-link to="/" v-if="white">
					<img src="../../assets/images/wmc-logo-white.svg" height="68px" alt="" />
				</router-link>
			</div>
			<ul class="menu_list">
				<li :class="[
          'menu_item',
          item.name === $route.meta.title ? 'menu_item_active' : '',
        ]" v-for="(item, index) in menuList" :key="index">
					<span>
						<router-link :to="item.path" class="menu-item-text">{{
            item.title
          }}</router-link>
					</span>
					<dl class="subnav" v-if="item.children && item.children.length !== 0">
						<dd v-for="(cont, i) in item.children" :key="i">
							<a :href="cont.path" v-if="cont.name === '大会看板' && lang == 'zh-CN'" target="_blank">{{ cont.title }}</a>
							<a href="https://board.wmconvention.com/dashboard?local=en-US"
								v-else-if="cont.name === '大会看板' && lang !== 'zh-CN'" target="_blank">{{ cont.title }}</a>
							<!-- <a @click="toKanban" v-if="cont.name === '大会看板'">{{
              cont.title
            }}</a> -->
							<router-link v-else :to="cont.path">{{ cont.title }}</router-link>
						</dd>
					</dl>
				</li>
			</ul>

			<div style="background-color: #ff0000;" class="wmc-header-search" v-if="lang == 'zh-CN'">
				<!-- <i
        class="wmc-header-search-icon wmc-hover"
        @click="showSearchBox = true"
      ></i> -->
				<div class="wmc-header-search-input" v-if="!showSearchBox">
					<input type="text" id="searchInput" placeholder="搜索" @keyup.enter="toSearch" @focus="toSearch"
						v-model="keysWord" />
					<i class="wmc-header-search-btn" @click="toSearch"></i>
				</div>


			</div>



			<div class="wmc-header-operation" style="right: 84px;">
				<el-select @change="changeLanguage" class="custom-select" style="width: 110px;" v-model="value"
					placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<!-- <div class="wmc-header-operation">
			<span class="wmc-hover" v-if="lang == 'zh-CN'">
				<router-link to="/wmcAbout#lxwm" class="menu-item-text">
					联系
				</router-link>
			</span>

			<i v-if="lang == 'zh-CN'">|</i>
			<span class="wmc-hover" id="demo4">
				<router-link to="/wmcAbout" class="menu-item-text">
					{{ $t("language.about") }}
				</router-link>
			</span>
		</div> -->
			<!-- <div
      class="wmc-header-language"
      @click="languageSelect('en-US')"
      v-if="language === 'zh-CN'"
    > -->
			<!-- 暂时隐藏 8.14 -->
			<!-- <div
      class="wmc-header-language"
      @click="languageSelect('en-US')"
      v-if="language === 'zh-CN'"
    >
      <span>中</span>
      <img
        v-if="!white"
        class="changeIcon"
        src="../../assets/images/banner/change-icon.png"
        alt=""
      />
      <img
        v-if="white"
        class="changeIcon"
        src="../../assets/images/banner/whiteChange.png"
        alt=""
      />
      <span>EN</span>
    </div> -->
			<!-- <div class="wmc-header-language" @click="languageSelect('zh-CN')" v-else>
      <span>EN</span>
      <img
        v-if="!white"
        class="changeIcon"
        src="../../assets/images/banner/change-icon.png"
        alt=""
      />
      <img
        v-if="white"
        class="changeIcon"
        src="../../assets/images/banner/whiteChange.png"
        alt=""
      />
      <span>中</span>
    </div> -->
			<!-- <div v-if="language === 'zh-CN'">
      <router-link to="/wmcEntrance" class="wmc-header-login"
        >登录/注册</router-link
      >
    </div> -->

			<img id="wmc-type-commom" src="../../assets/images/wmc-menu.png" class="wmc-menu wmc-type-commom" alt=""
				v-if="white" @click="showMobileMenu" />
			<img src="../../assets/images/wmc-menu-white.png" class="wmc-menu wmc-type-white" alt="" v-if="!white"
				@click="showMobileMenu" />
		</div>
	</div>
</template>
<script>
	import {
		Message
	} from "element-ui";
	import "element-ui/lib/theme-chalk/message.css";
	export default {
		name: "NavBar",
		data() {
			return {
				showSearchBox: false,
				keysWord: "",
				value: 'zh-CN',
				options: [{
						value: "zh-CN",
						label: "中文-简体",
					},
					{
						value: "en-US",
						label: "ENGLISH",
					},
				],
				language: window.sessionStorage.getItem("currLang") ?
					window.sessionStorage.getItem("currLang") : "zh-CN",
				// 其他语言下展示的导航
				enMenuItem: [
					"/index",
					"/wmcActivities",
					"/wmcService",
					"/wmcAbout",
					"/wmcAlipay"
				],
				excludeSubMenuItem: ["/wmcService#pzbl"],
			};
		},
		computed: {
			white: {
				set(val) {
					this.$store.commit("SET_WHITE_HEADER", val);
				},
				get() {
					let clientWidth = document.documentElement.clientWidth;
					var flag = "";
					if (clientWidth < 1024) {
						flag = true;
					} else {
						flag = this.$route.name == "index" ? this.$store.state.white : "true";
					}
					return flag;
				},
			},
			menuList() {
				let cMenu = [];
				let that = this;
				[{
						title: this.$t("language.index"),
						path: "/index",
						children: [],
						name: "首页",
					},
					{
						title: this.$t("language.newsCenter"),
						path: "/newsCenter",
						name: "资讯中心",
						children: [{
								title: this.$t("language.conventionUpdates"),
								path: "/newsCenter#dhdt",
								name: "资讯中心",
							},
							{
								title: this.$t("language.announcements"),
								path: "/newsCenter#tzgg",
								name: "资讯中心",
							},
							{
								title: this.$t("language.mediaCoverage"),
								path: "/newsCenter#mtbd",
								name: "资讯中心",
							},
							{
								title: this.$t("language.highlights"),
								path: "/newsCenter#jcsj",
								name: "资讯中心",
							},
						],
					},
					{
						title: this.$t("language.conventionActivities"),
						path: "/wmcActivities",
						name: "大会活动",
						children: [{
								title: this.$t("language.majorEvents"),
								path: "/wmcActivities#zdhd",
								name: "大会活动",
							},
							{
								title: this.$t("language.projectDocking"),
								path: "/wmcActivities#lbdj",
								name: "大会活动",
							},
							{
								title: this.$t("language.thematicActivities"),
								path: "/wmcActivities#zthd",
								name: "大会活动",
							}
						],
					},
					{
						title: this.$t("language.exhibitions"),
						path: "/wmcShow",
						children: [],
						name: "大会展示",
					},
					{
						title: "大会发布",
						path: "/wmcPublish",
						children: [],
						name: "大会发布",
					},
					// {
					//   title: "供需对接",
					//   path: "/supplyDemandDocking",
					//   children: [],
					//   name: "供需对接",
					// },
					// {
					//   title: "线上展会",
					//   path: "/cloudShow",
					//   children: [],
					//   name: "线上展会",
					// },
					{
						title: this.$t("language.conventionServices"),
						path: "/wmcService",
						name: "大会服务",
						children: [{
								title: this.$t("language.ticketHandling"),
								path: "/wmcService#pzbl",
								name: "大会服务",
							},
							// {
							// 	title: this.$t("language.brandCooperation"),
							// 	path: "/wmcService#pphz",
							// 	name: "大会服务",
							// },
							// {
							//   title: this.$t("language.investmentServices"),
							//   path: "/wmcService#tzfw",
							//   name: "大会服务",
							// },
							{
								title: this.$t("language.dataDownloading"),
								path: "/wmcService#zlxz",
								name: "大会服务",
							},
							{
								title: this.$t("language.exhibitionLayout"),
								//	path: "/wmcShow",
								path: "/wmcService#zqsz",
								name: "大会服务",
							},
							{
								title: this.$t("language.trafficGuide"),
								path: "/wmcService#jtzy",
								name: "大会服务",
							},
							{
								title: this.$t("language.conferenceSignage"),
								path: "https://board.wmconvention.com/dashboard?local=zh-CN",
								name: "大会看板",
							},
							{
								title: this.$t("language.questionsAnswers"),
								path: "/wmcService#cjwt",
								name: "大会服务",
							},
							{
								title: this.$t("language.contactUs"),
								path: "/wmcService#lxwm",
								name: "大会服务",
							},
						],
					},
					{
						title: this.$t("language.Alipay"),
						path: "/wmcAlipay",
						children: [],
						name: "支付宝",
					},
					{
						title: this.$t("language.about"),
						path: "/wmcAbout",
						children: [{
								title: this.$t("language.conventionIntroduction"),
								path: "/wmcAbout#dhjj",
								name: "关于大会",
							},
							{
								title: this.$t("language.AnhuiProvincialSituation"),
								path: "/wmcAbout#ahsq",
								name: "关于大会",
							},
							{
								title: this.$t("language.organizationalStructure"),
								path: "/wmcAbout#zzjg",
								name: "关于大会",
							},
						],
						name: "关于大会",
					},

				].forEach(function(item) {
					if (that.lang !== "zh-CN") {
						if (that.enMenuItem.includes(item.path)) {
							let children = item.children;
							item.children = children.filter(
								(i) => !that.excludeSubMenuItem.includes(i.path)
							);
							cMenu.push(item);
						}
					} else {
						// if (item.name === "支付宝") {
						// 	return;
						// } else {
						// 	cMenu.push(item);
						// }
						cMenu.push(item);
					}
				});
				return cMenu;
				// return [
				//   {
				//     title: this.$t("language.index"),
				//     path: "/index",
				//     children: [],
				//     name: "首页",
				//   },
				//   {
				//     title: "资讯中心",
				//     path: "/newsCenter",
				//     name: "资讯中心",
				//     children: [
				//       {
				//         title: "大会动态",
				//         path: {
				//           path: "moreNews",
				//           query: {
				//             title: "dhdt",
				//           },
				//         },
				//         name: "资讯中心",
				//       },
				//       {
				//         title: "通知公告",
				//         path: {
				//           path: "moreNews",
				//           query: {
				//             title: "tzgg",
				//           },
				//         },
				//         name: "资讯中心",
				//       },
				//       {
				//         title: "媒体报道",
				//         path: {
				//           path: "moreNews",
				//           query: {
				//             title: "mtbd",
				//           },
				//         },
				//         name: "资讯中心",
				//       },
				//       { title: "精彩瞬间", path: "/moreNewsImg", name: "资讯中心" },
				//     ],
				//   },
				//   {
				//     title: this.$t("language.conventionActivities"),
				//     path: "/wmcActivities",
				//     name: "大会活动",
				//     children: [
				//       {
				//         title: this.$t("language.majorEvents"),
				//         path: "/wmcActivities#zdhd",
				//         name: "大会活动",
				//       },
				//       {
				//         title: this.$t("language.matchmakingConference"),
				//         path: "/wmcActivities#lbdj",
				//         name: "大会活动",
				//       },
				//       {
				//         title: this.$t("language.specialActivities"),
				//         path: "/wmcActivities#zthd",
				//         name: "大会活动",
				//       },
				//       {
				//         title: this.$t("language.parallelForum"),
				//         path: "/wmcActivities#pxlt",
				//         name: "大会活动",
				//       },
				//       // {
				//       //   title: this.$t("language.marketizationForum"),
				//       //   path: "/wmcActivities#schlt",
				//       // },
				//     ],
				//   },
				//   { title: "展览展示", path: "/wmcShow", children: [], name: "展览展示" },
				//   {
				//     title: "发布活动",
				//     path: "/wmcPublish",
				//     children: [],
				//     name: "发布活动",
				//   },
				//   {
				//     title: "供需对接",
				//     path: "/supplyDemandDocking",
				//     children: [],
				//     name: "供需对接",
				//   },
				//   {
				//     title: "线上展会",
				//     path: "/cloudShow",
				//     children: [],
				//     name: "线上展会",
				//   },
				//   {
				//     title: this.$t("language.conventionServices"),
				//     path: "/wmcService",
				//     name: "大会服务",
				//     children: [
				//       {
				//         title: this.$t("language.epidemicPreventionTips"),
				//         path: "/wmcService#fyts",
				//         name: "大会服务",
				//       },
				//       {
				//         title: this.$t("language.ticketHandling"),
				//         path: "/wmcService#pzbl",
				//         name: "大会服务",
				//       },
				//       {
				//         title: this.$t("language.brandCooperation"),
				//         path: "/wmcService#pphz",
				//         name: "大会服务",
				//       },
				//       {
				//         title: this.$t("language.investmentServices"),
				//         path: "/wmcService#tzfw",
				//         name: "大会服务",
				//       },
				//       {
				//         title: this.$t("language.trafficGuide"),
				//         path: "/wmcService#jtzy",
				//         name: "大会服务",
				//       },
				//       {
				//         title: this.$t("language.exhibitionAndConventionCenter"),
				//         path: "/wmcService#hzzx",
				//         name: "大会服务",
				//       },
				//     ],
				//   },
				// ].filter((item) => {
				//   return this.lang !== "zh-CN"
				//     ? this.enMenuItem.includes(item.path)
				//     : true;
				// });
			},
			lang() {
				return this.$store.state.lang;
			},
		},
		watch: {
			$route() {
				this.jumpPage();
			},
		},
		mounted() {
			this.value = this.$store.state.lang;
			if (this.$store.state.lang == "zh-CN") {
				this.languageVersion = 1;
			} else {
				this.languageVersion = 2;
			}

			this.handleResize();

			window.addEventListener('resize', this.handleResize);
		},
		methods: {
			handleResize() {
				console.log("=====handleResize")
				// let img = document.getElementById("imgbanner");
				// var height = img.clientHeight;
				// document.getElementById("wmc-header").style.top = height + "px";
				// document.getElementById("wmc-type-commom").style.top = (10 + height) + "px";

				//			document.getElementById("wmc-footer").style.marginBottom = (10 + height) + "px";
				// 这里可以添加更多的处理逻辑
			},
			toKanban() {
				Message({
					showClose: true,
					message: "敬请期待",
					customClass: "my-message",
				});
				// this.$router.push("/wmcEntranceNew");
			},
			jumpPage() {
				//跳转锚节点
				var hash = window.location.hash;
				// 获取 url 的 # 结束的位置
				var index = hash.lastIndexOf("#");
				// 获取 以 # 位置到最后的位置锚点
				var id = hash.substring(index + 1, hash.length + 1);
				var div = document.getElementById(id);
				if (index != -1 && div) {
					setTimeout(() => {
						div.scrollIntoView({
							behavior: "smooth"
						});
					}, 200);
				}
			},
			toSearch() {
				this.showSearchBox = false;
				this.$router.push({
					name: "wmcSearch",
					params: {
						searchWord: this.keysWord
					},
				});
				this.keysWord = "";
			},
			showMobileMenu() {
				this.$store.commit("SET_SHOW_MOBILE_MENU");
			},
			changeLanguage() {
				this.language = this.value;
				this.$nextTick(() => {
					this.$i18n.locale = this.language; //切换为中文
					window.sessionStorage.setItem("currLang", this.language);
					this.$store.commit(
						"SET_LANG",
						window.sessionStorage.getItem("currLang")
					);
					// 如果当前路由地址在 多语言状态下导航需要展示的 数组中，直接翻译即可，否则，翻译后跳转到首页
					let currPath = this.$route.path;
					if (!this.enMenuItem.includes(currPath) || currPath === "/wmcAlipay")
						this.$router.push("/");
				});
			},
			languageSelect(val) {
				this.language = val;
				this.$nextTick(() => {
					this.$i18n.locale = val; //切换为中文
					window.sessionStorage.setItem("currLang", val);
					this.$store.commit(
						"SET_LANG",
						window.sessionStorage.getItem("currLang")
					);
					// 如果当前路由地址在 多语言状态下导航需要展示的 数组中，直接翻译即可，否则，翻译后跳转到首页
					let currPath = this.$route.path;
					if (!this.enMenuItem.includes(currPath) || currPath === "/wmcAlipay")
						this.$router.push("/");
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	@import "@/assets/styles/navbar.scss";

	.custom-select /deep/ .el-input__inner {

		color: #568abd !important;
		border: 1px solid #e1e9fd;
		height: 36px;
		border-radius: 10px;
		margin-bottom: 8px;
		background: rgba(255, 255, 255, 0.8);

	}

	.custom-select /deep/ .el-input__icon {
		height: auto;
		color: #568abd !important;
	}

	.custom-select /deep/ .el-input__inner::placeholder {
		color: #568abd;
	}

	@media screen and (min-width: 1024px) and (max-width: 1600px) {
		.wmc-header-operation {
			right: 30px !important;
		}

		.custom-select /deep/ .el-input__inner {

			height: 27px !important;
			font-size: 12px;

		}


		.custom-select {
			width: 90px !important;

		}


		.custom-select /deep/ .el-input__icon {
			height: auto;
			color: #568abd !important;
		}

		.custom-select /deep/ .el-input__inner::placeholder {
			color: #568abd;
		}
	}
</style>