export const language = {
	exhibitAreaInfo: "Exhibit Area Info",
	highlights: "Highlights",
	mediaCoverage: "Media Coverage",
	announcements: "Announcements",
	conventionUpdates: "Convention Updates",
	newsCenter: "News Center",
	// 以上2024新

	index: "Homepage",
	conventionActivities: "Convention Activities",
	exhibitions: "Convention Exhibits",
	conventionServices: "Convention Services",
	about: "About the Convention",
	majorEvents: "Major Events",
	projectDocking: "Key Project Matchmaking",
	thematicActivities: "Special Activities",
	forumActivities: "Forums",
	professionalForum: "Specialized Forums",
	marketizationForum111: "City-Sponsored Events​",
	bulletinBoard: "大会看板",
	epidemicPreventionTips: "防疫提示",
	ticketHandling: "Ticket processing",
	brandCooperation: "Brand Collaborations",
	investmentServices: "投资服务",
	trafficGuide: "Transportation Guidelines",
	exhibitionAndConventionCenter: "会展中心",
	Alipay: "Payment Service Guide",
	conferenceSignage: "Convention Signage",
	eventSchedule: "Event Schedule",
	activityTime: "Activity time",
	activityAddress: "Activity adress",
	responsibleUnit: "Responsible unit",
	dataTime: "Time: ",
	address: "Venue: ",
	content: "Details: ",
	responseOrganization: "Organizers: ",
	// 敬请期待
	jqqd: "Stay tuned...",
	more: "more",

	// 主办单位
	sponsor: "Organizers",
	// 安徽省人民政府
	ahsrmzf: "Anhui Provincial People’s Government",
	// 国家制造强国建设战略咨询委员会
	gjzzqgjszlwyh: "National Manufacturing Power Construction Strategy Advisory Committee",
	// 中国中小企业协会
	zgzxqyxh: "China Association of Small and Medium Enterprises",
	// 全球中小企业联盟
	qqzxqylm: "Global Alliance of Small and Medium Enterprises",
	// 国务院国资委
	// gzw: "国务院国资委",
	// 中国工程院
	// zggcy: "中国工程院",
	// 国务院发展研究中心
	// gwyfzyjzx: "国务院发展研究中心",
	// 全国工商联
	// qggsl: "全国工商联",
	// 全国对外友协
	// qgdwyx: "全国对外友协",
	// 全国中小企业协会
	// qgzxqyxh: "中国中小企业协会",
	// 全球中小企业联盟
	// qqzxqylm: "全球中小企业联盟",

	// 支持单位:
	supportingAgency: "Supporting Organizations",
	// 中国侨联
	zgql: "中国侨联",
	// 中国机械工业集团有限公司
	zgjxgyjt: "中国机械工业集团有限公司",
	// 中国企业联合会
	zgqylhh: "中国企业联合会",
	// 中国机械工业联合会
	zgjxgylhh: "中国机械工业联合会",
	// 中国光伏行业协会
	zggfhyxh: "中国光伏行业协会",
	// 中国电子信息产业发展研究院
	zgdzxxcyfayjy: "中国电子信息产业发展研究院",

	zggyhlwyjy: "中国工业互联网研究院",


	// 承办单位
	organizer: "Host Organizations",
	// 安徽省经济和信息化厅
	sgyhxxht: "省工业和信息化厅",

	// 安徽省商务厅
	ansswt: "省商务厅",
	// 省外办（省港澳办）
	swbswt: "省外办（省港澳办）",
	// 省国资委
	sgzw: "省国资委",
	// 省贸促会
	smch: "省贸促会",
	// 合肥市人民政府
	hfsrmzf: "合肥市人民政府",

	// 距2023世界制造业大会开幕
	djs1: "The Opening of The Conference",
	// 倒计时    天：
	djs2: "Days Left Until the Convention Commences",
	// 线上展会：
	onlineFair: "Online Exhibition Hall",
	// 进入展厅：
	entertheExhibitionHall: "World Manufacturing Convention Live Show",
	// 进入2021届世界制造业大会云看展：
	virtualExhibitionof2021WorldManufacturingConvention: "2024 World Manufacturing Convention Virtual Exhibition",
	// 精彩瞬间：
	brilliantMoments: "Highlights",
	// 六百对接：（中文专有名词，无对应英文译文，建议翻译成“对接会”，按原文直译的话外国人也不明白是什么意思）
	matchmakingConference: "六百对接",
	// 大会简介：
	conventionIntroduction: "Convention Introduction",
	// 组织架构：
	organizationalStructure: "Organizational Structure",
	// 组织架构：
	AnhuiProvincialSituation: "Anhui Updates",
	// 资料下载：
	dataDownloading: "Document Downloads",
	// 常见问题：
	questionsAnswers: "Frequently Asked Questions",
	// 联系我们：
	contactUs: "Contact Us",
	// 展馆布局
	exhibitionLayout: "Exhibition Layout",

	wmc: "World Manufacturing Convention",
	nameOfConference: "Name of the conference",
	//大会主题
	themeOfConference: "Theme of the conference",
	//参展嘉宾
	guestsAtExhibition: "Guests at the exhibition",
	//展览成效
	exhibitionEffectiveness: "Exhibition effectiveness",
	// 往届回顾
	review: "Convention History & Review",
	liveUrl: "Live/Recorded Url",
	host: "Host",
	pending: "Pending",
	agenda: "Agenda",
};